<template>
  <div class="edit-clan-modal" @click.stop="">
    <h2>{{this.clan.name}}</h2>
    <div class="input-group">
      <label for="clan-name">Description</label>
      <input v-model="this.clan.description" type="text" id="clan-name" placeholder="Enter clan description" disabled />
    </div>
    <div class="user-list">
      <h3>Clan members</h3>
      <ul>
        <li v-for="(user, index) in this.clan.members" :key="index">
          {{ user.username }}
          <button @click="removeUser(index)" v-if="user.user_id != this.userStore.getUser.id && !!this.$props.edit">Исключить</button>
        </li>
      </ul>
    </div>

    <div class="action-buttons" v-if="this.$props.edit">
      <div class="btn" @click="submitChanges" >
        <span>SAVE</span>
      </div>
      <!--      <button @click="submitChanges" class="save-btn">Сохранить</button>-->
      <div class="btn" @click="this.$emit('close')">
        <span>CLOSE</span>
      </div>
      <!--      <button @click="$emit('close')" class="cancel-btn">Отменить</button>-->
    </div>

    <div class="action-buttons" v-else>
      <div class="btn" @click="this.exitFromClan">
        <span>EXIT FROM CLAN</span>
      </div>
      <div class="btn" @click="this.$emit('close')">
        <span>CLOSE</span>
      </div>
      <!--      <button  class="save-btn">Закрыть</button>-->
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/userStore";

export default {
  name: 'ModalClanEditComponent',
  props: {
    clanId: {
      required: true,
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clan: {},
      removedUsers: [],
    };
  },
  emits: ['update', 'close', 'quitClan'],
  methods: {
    removeUser(index) {
      this.removedUsers.push(this.clan.members[index]);

      this.clan.members.splice(index, 1); // Удаление пользователя из списка
    },
    submitChanges() {
      if (this.removedUsers.length > 0) {
        this.userStore.removeUsers(this.$props.clanId, this.removedUsers)
            .then(() => {
              this.$emit('close')
            })
      } else {
        this.$emit('close')
      }
    },
    exitFromClan() {
      this.userStore.removeUsers(this.$props.clanId, [this.clan.members.find((item) => item.user_id == this.userStore.getUser.id)])
          .then(() => {
            this.$emit('quitClan')
            this.$emit('close')
          })
    },
  },
  mounted() {
    this.userStore.fetchClan(this.$props.clanId)
        .then((res) => {
          this.clan = res.data
        })
  },
  setup() {
    const userStore = useUserStore()

    return {userStore}
  }
};
</script>
<style lang="scss" scoped>
.edit-clan-modal {

  .btn {
    display: flex;
    justify-content: center;
    padding: 4px 10px 4px 10px;
    border-radius: 20px;
    border: 1px solid aqua;
    background-color: transparent;
    box-shadow: inset 0 -5px 6px rgba(0, 183, 255, 0.4);
    align-items: center;
    min-width: 72px;
    span {
      color: white;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: bold;
      height: min-content;
    }
  }


  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #021C3D;
  width: 300px;
  text-align: center;

  color: white;

  h2 {
    text-align: center;
    //margin-bottom: 20px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }

  .input-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      color: aqua;
    }

    input, textarea {
      width: 95%;
      padding: 10px;
      border-radius: 10px;
      //border: 1px solid #4a4a4a;
      background-color: #1b365d;
      color: #fff;

      outline: 1px solid aqua;
    }

    textarea {
      border: 1px solid aqua;
      resize: none;
      height: 80px;
    }
  }

  .user-list {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      color: aqua;
    }

    ul {
      list-style: none;
      padding: 0;

      outline: 1px solid aqua;
      border-radius: 10px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1b365d;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;

        button {
          background-color: #ff4d4d;
          border: none;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          color: white;
          &:hover {
            background-color: #ff6666;
          }
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;

    .save-btn {
      background-color: #00e6e6;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #00cccc;
      }
    }

    .cancel-btn {
      background-color: #b3b3b3;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #a6a6a6;
      }
    }
  }
}
</style>
