<template>
  <div class="pause-menu">
    <h2>You are banned!</h2>
    <div class="modal_content">
      <h3>You can still play, but you are not counted in the ranking</h3>
      <div class="action-buttons">
        <button @click="this.$emit('close')">BACK</button>
      </div>
    </div>

  </div>
</template>

<script>
import {useUserStore} from "@/stores/userStore";

export default {
  name: 'BanModalComponent',
  props: {},
  data() {
    return {
      clan: {},
      removedUsers: [],
    };
  },
  emits: ['update', 'close', 'quitClan'],
  methods: {},
  mounted() {

  },
  setup() {
    const userStore = useUserStore()

    return {userStore}
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/font.scss';
@import "@/assets/style/modal.scss";

.pause-menu {
  outline: 1px solid aqua;
  background-color: #0A1A34;
  color: white;
  border-radius: 15px;
  width: 300px;
  //padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.pause-menu h2 {
  padding-bottom: 20px;
  border-bottom: 1px solid aqua;
  width: 100%;
  //margin-bottom: 20px;
  font-size: 24px;

}

.modal_content {
  padding: 10px;


  .action-buttons {
    display: flex;
    justify-content: space-around;

    button {
      background-color: transparent;
      border: 2px solid #00BFFF;
      color: white;
      padding: 10px 20px;
      border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

  }
}

</style>