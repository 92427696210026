import {
    defineStore
} from 'pinia'
import instance from "@/http-common";
import axios from "axios";

export const useUserStore = defineStore('users', {
    state: () => ({
        user: {
            id: null,

            systemId: null,

            name: 'guest',

            refs: 0,

            ban: false,
            banShowed: false,
        },

        clan: {
            name: 'Join clan',
            owner: false,
            data: {},
        },

        auth: false,
        score: '000000',

        tasks: [],
        completedTasks: [],
        top: {
            clan: [],
            players: []
        },

        audio: false,
        audioLoaded: false,
    }),
    getters: {
        getUser: (state) => {
            return state.user
        },
        getBannedShowed: (state) => {
            return state.user.banShowed
        },
        isBanned: (state) => {
            return state.user.ban
        },
         getScore: (state) => {
            return state.score
        },
        getAuthState: (state) => {
            return state.auth
        },
        getAudioState: (state) => {
            return state.audio
        },
        getTop: (state) => {
            return state.top
        },
        getClan: (state) => {
            return state.clan;
        },
        getHaveClan: (state) => {
            return state.clan.data.id ? true : false;
        },
        getClanOwnership: (state) => {
            if (state.clan.data.owner_id == state.user.systemId) {
                return true
            }

            return false
        },

        getRefs() {
            return this.user.refs
        },
        getCompletedTasks() {
            return this.completedTasks
        }
    },
    actions: {
        setAudioLoaded() {
            this.audioLoaded = true
        },
        initUser(user) {
            return new Promise((resolve, reject) => {

                const url = `users`

                instance.post(url, user)
                    .then((res) => {
                        this.user.id = res.data.user_id;
                        this.user.name = res.data.username;
                        this.user.systemId = res.data.id

                        this.auth = true

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log('error from init')
                        // пользователь уже есть
                        this.user.id = user.user_id
                        this.fetchUserInfo().then((res) => {
                            this.user.name = res.data.username;
                            this.user.systemId = res.data.id
                            this.auth = true
                            resolve(res)
                        }).catch((error) => {
                            console.log('error from fetch')
                            this.user.id = null
                            alert('userStore error | failed to get user data')
                            reject([err, error])
                        })
                    })
            })
        },

        toggleAudio() {
            this.audio = !this.audio
        },



        fetchUserInfo() {
            return new Promise((resolve, reject) => {

                const url = `users/${this.user.id}`

                instance.get(url)
                    .then((res) => {
                        this.user.systemId = res.data.id

                        this.user.refs = res.data.refs_amount

                        if (res.data.clans.length > 0) {
                            console.log(true)
                            this.clan.data = res.data.clans[0]
                            this.clan.owner = res.data.clans[0].is_owner
                            this.clan.name = res.data.clans[0].name
                        } else {
                            this.clan = {
                                name: 'Join clan',
                                owner: false,
                                data: {},
                            }
                        }
                        this.user.ban = res.data.ban
                        this.score = res.data.points.toString().padStart(6, '0')
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchTasks() {
            return new Promise((resolve, reject) => {

                const url = `/task`

                instance.get(url)
                    .then((res) => {
                        this.tasks = res.data.tasks;
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        finishTask(id) {
            return new Promise((resolve, reject) => {

                const url = `/task/complete`

                const data = {
                    user_id: this.user.id,
                    task_id: id
                }

                instance.post(url, data)
                    .then((res) => {
                        // this.tasks = res.data.tasks;
                        this.completedTasks.push(id)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchTop() {
            return new Promise((resolve, reject) => {
                const urls = {
                    users: 'users/filter',
                    clans: 'clans'
                }
                axios.all([
                    instance.get(urls.users),
                    instance.get(urls.clans)
                ])
                    .then(axios.spread((usersData, clansData) => {
                        // // output of req.
                        this.top.clan = clansData.data
                        this.top.players = usersData.data
                        resolve({
                            clans: clansData.data,
                            players: usersData.data
                        })
                    })).catch((err) => {
                    reject(err)
                });
            })
        },
        fetchClan(clanId) {
            return new Promise((resolve, reject) => {

                const url = `clans/${clanId}`

                instance.get(url)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createClan(data) {
            return new Promise((resolve, reject) => {

                const url = 'clans'

                instance.post(url, {
                    name: data.name,
                    description: data.description,
                    owner_id: this.user.id
                })
                    .then((res) => {
                        this.clan.name = res.data.name;
                        this.clan.data = res.data
                        this.clan.owner = true
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        removeUsers(clanId, users) {
            return new Promise((resolve, reject) => {
                let requests = []

                users.forEach((user) => {
                    requests.push(instance.delete(`users/${user.user_id}/clan/${clanId}`))
                })

                axios.all(requests)
                    .then(axios.spread((...responses) => {
                        if (users.find((item) => item.user_id == this.user.id)) {
                            console.log()
                            this.clan = {
                                name: 'Join clan',
                                owner: false,
                                data: {},
                            }
                        }
                        resolve(responses)
                    })).catch((err) => {
                    reject(err)
                });
            })
        },
        joinClan(clanId) {
            return new Promise((resolve, reject) => {

                const url = `users/${this.user.id}/clan/${clanId}`

                instance.post(url)
                    // eslint-disable-next-line no-unused-vars
                    .then((res) => {
                        this.fetchClan(clanId).then((res) => {
                            this.clan.name = res.data.name;
                            this.clan.data = res.data
                            this.clan.owner = false
                            resolve(res)
                        })
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        setScore(score, seconds) {
            return new Promise((resolve, reject) => {

                const url = `users/${this.user.id}`

                instance.put(url, {
                    record: score,
                    time: seconds
                })
                    .then((res) => {
                        this.score = res.data.record
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
    persist: true,
})