<template>
  <div class="main">

    <PreloaderComponent v-if="getLoaded" :text="this.errors"/>
    <header-component v-if="loaded.user" :username="username"></header-component>
    <Transition name="bounce">
      <div class="modal_create" v-if="this.wallet" @click="this.wallet = false">
        <WalletComponent @close="() => this.wallet = false"/>
      </div>
    </Transition>

    <Transition name="bounce">
      <div class="modal_create" v-if="this.banned.open" @click="this.banned.open = false">
        <BanModalComponent @close="() => this.banned.open = false"/>
      </div>
    </Transition>

    <div class="center-top">
      <div class="left" @click="this.wallet = true">
        <img src="@/assets/wallet.svg" alt="" class="wallet-pic">
        <span>wallet</span>
      </div>

      <div class="center-score">
        <span style="color:aqua">your score</span>
        <span>{{ score }}</span>
      </div>

      <div class="right">
        <span>sound</span>
        <img @click="muteSound" :class="!sound ? 'cross' : ''" src="@/assets/sound.svg" alt="" class="sound-pic">
      </div>
    </div>

    <div class="center-middle">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="">
      </div>
      <div class="play">
        <router-link to="/about">
          <img src="@/assets/btn.svg" alt="">
        </router-link>
      </div>

      <div class="bottom">
        <router-link to="/task" style="display: contents">
          <div class="btn">
            <span>TASKS</span>
          </div>
        </router-link>

        <router-link to="/top" style="display: contents">
          <div class="btn">
            <span>TOP / CLANS</span>
          </div>
        </router-link>

        <div class="btn" @click="shareGame">
          <span>INVITE</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import {useUserStore} from "@/stores/userStore";
import {useAssetsStore} from "@/stores/assetsStore";
import PreloaderComponent from "@/components/Preloader.vue";
import {storeToRefs} from "pinia";
import WalletComponent from "@/components/WalletComponent.vue";
import BanModalComponent from "@/components/BanModalComponent.vue";
import {genName} from "@vicimpa/nick-name";

export default {
  components: {BanModalComponent, WalletComponent, PreloaderComponent, HeaderComponent},
  data() {
    return {
      username: this.userStore.getUser.name,
      score: this.userStore.getScore,
      sound: this.userStore.getAudioState,
      loaded: {
        user: false,
        assets: false,
        eggs: false
      },
      wallet: false,
      banned: {
        open: false,
        showed: false
      },
      errors: [],
    };
  },
  computed: {
    getLoaded() {
      if (this.loaded.assets == true && this.loaded.user == true && this.loaded.eggs == true) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    translit(word) {
      var converter = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
        'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya'
      };

      word = word.toLowerCase();

      var answer = '';
      for (var i = 0; i < word.length; ++i) {
        if (converter[word[i]] == undefined) {
          answer += word[i];
        } else {
          answer += converter[word[i]];
        }
      }

      answer = answer.replace(/[^-0-9a-z]/g, '-');
      answer = answer.replace(/[-]+/g, '-');
      answer = answer.replace(/^\-|-$/g, '');
      return answer;
    },
    initUser() {
      const urlParams = new URLSearchParams(window.location.search)

      const data = {
        user_id: `${this.tele.initDataUnsafe.user?.id}`,
        username: this.tele.initDataUnsafe.user.first_name && this.tele.initDataUnsafe.user.first_name.length > 0 ? this.translit(this.tele.initDataUnsafe.user.first_name) : genName(3),
        ref: urlParams.get('ref') || 0,
      }

      this.userStore.initUser(data).then(() => {
        this.username = this.userStore.getUser.name;
        this.score = this.userStore.getScore;
        this.loaded.user = true;
      }).catch((err) => {
        alert('data error', JSON.stringify(err))
      });
    },
    muteSound() {
      this.sound = !this.userStore.getAudioState;
      this.userStore.toggleAudio();
    },
    shareGame() {
      this.$router.push({name: 'Invite'})
    },
  },
  mounted() {
    // alert('start loading')
    this.loaded.user = false
    if (!this.userStore.getAuthState) {
      this.initUser();
    } else {
      this.userStore.fetchUserInfo().then(() => {
        this.score = this.userStore.getScore;

        if (this.userStore.isBanned && !this.userStore.getBannedShowed) {
          this.banned.open = true

          this.userStore.user.banShowed = true
        }
      })
          .catch((err) => {
            alert(JSON.stringify(err))
          })
          .finally(() => {
            this.loaded.user = true;
          });
    }

    this.assetsStore.getCharacters()
        .then(() => {
          // alert('characters loaded')
          this.loaded.assets = true
        })
        .catch((err) => {
          this.errors.push(err)
        })

    this.assetsStore.getEggs()
        .then(() => {
          // alert('egg loaded')
          this.loaded.eggs = true
        })
        .catch((err) => {
          console.log(err)
          this.errors.push(err)
        })
  },
  setup() {
    const userStore = useUserStore();
    const assetsStore = useAssetsStore()

    const {audioLoaded} = storeToRefs(userStore);

    const tele = window.Telegram.WebApp

    return {tele, userStore, assetsStore, audioLoaded};
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/font.scss';
@import "@/assets/style/modal.scss";

.main {
  position: fixed;
  background-image: url("@/assets/background.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100svh;
  width: 100svw;
}

.center-top {
  display: flex;
  justify-content: space-between;
  //padding: 16px;
  width: 100%;

  .left, .right {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .wallet-pic, .sound-pic {
    width: 32px;
    height: 32px;
  }

  span {
    color: aqua;
    font-family: 'Montserrat';
    font-size: 21px;
  }

  .center-score {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .center-score span {
    color: white;
    font-family: 'Montserrat';
    font-size: 1em;
  }

  .cross {
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
    color: aqua;
  }
}

.center-middle {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    display: contents;
  }

  .logo img {
    height: 30svh;
  }

  .play img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: glow 1.5s infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 5px rgba(0, 217, 255, 0.5);
    }
    50% {
      box-shadow: 0 0 20px rgb(0, 242, 255);
    }
    100% {
      box-shadow: 0 0 5px rgba(4, 234, 255, 0.766);
    }
  }

  .bottom {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 2svh;
    align-items: center;

    .btn {
      display: flex;
      justify-content: center;
      width: 60svw;
      padding: 4px;
      border-radius: 20px;
      border: 1px solid aqua;
      background-color: transparent;
      box-shadow: inset 0 -5px 6px rgba(0, 183, 255, 0.4);

      span {
        color: white;
        font-family: 'Montserrat';
        font-size: 21px;
        font-weight: bold;
      }
    }
  }
}
</style>
