<template>
  <div class="preloader">
    <div class="preloader-circle"></div>
    <div class="preloader-text">Loading...</div>
  </div>
</template>

<script>
export default {
  name: 'PreloaderComponent',
};
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader-circle {
  width: 80px;
  height: 80px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  border-top: 10px solid #00ffcc;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader-text {
  margin-top: 20px;
  font-size: 1.5rem;
  color: aqua;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px #00ffcc, 0 0 20px #00ffcc, 0 0 30px #00ffcc, 0 0 40px #00ffcc;
  }
  100% {
    text-shadow: 0 0 20px #00ffcc, 0 0 30px #00ffcc, 0 0 40px #00ffcc, 0 0 50px #00ffcc;
  }
}
</style>
