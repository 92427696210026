<template>
  <div class="main">
    <header-component :username="username"></header-component>
    <div class="center-top">
      <div class="home-button" @click="this.$router.push('/')">
        <img src="@/assets/home.svg">
      </div>
      <span>CHOOSE YOUR CHARACTER</span>
      <div />
    </div>

    <div class="center-middle">
      <div class="character" :class="{ shake: shakeCharacter }">
        <img :src="'data:image/png;base64,' + characters[currentCharacterIndex].selection_svg" alt="">
      </div>
      <div class="select">
        <div class="left" @click="changeCharacter(-1)">
          <img src="@/assets/next.svg" alt="">
        </div>

        <div class="name">
          <span>{{ characters[currentCharacterIndex].visible_name }}</span>
        </div>

        <div class="right" @click="changeCharacter(1)">
          <img src="@/assets/next.svg" alt="">
        </div>
      </div>
    </div>

    <div class="center-bottom">
      <div class="play">
        <router-link :to="`/game/${characters[currentCharacterIndex].id}`">
          <img src="@/assets/btn.svg" alt="">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import {useUserStore} from "@/stores/userStore";
import {useAssetsStore} from "@/stores/assetsStore";

export default {
  components: {HeaderComponent},
  data() {
    return {
      username: this.userStore.getUser.name,
      currentCharacterIndex: 0,
      characters: this.assetStore.characters,
      shakeCharacter: false,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.username = urlParams.get("username") || this.username;
  },
  setup() {
    const userStore = useUserStore()
    const assetStore = useAssetsStore()

    return {userStore,assetStore}
  },
  methods: {
    changeCharacter(direction) {
      this.shakeCharacter = true;
      this.currentCharacterIndex = (this.currentCharacterIndex + direction + this.characters.length) % this.characters.length;
      this.shakeCharacter = false;
      // }, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/font.scss';

@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.main {
  width: 100svw;

  .center-top {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;

    .home-button {
      //padding-left: 28px;
      img {
        height: 30px;
      }
    }

    span {
      display: flex;
      justify-content: center;
      grid-area: main;
      color: aqua;
      font-weight: bold;
      font-family: 'Montserrat';
    }
  }

  .center-middle {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    .character {
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        min-height: 40svh;
        height: 40svh;
        animation: pulse 2.5s infinite;
      }

      &.shake {
        //animation: shake 0.5s;
      }
    }

    .select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 32px;

      .name {
        min-height: 40%;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid aqua;
        background-color: transparent;
        animation: glow 1.5s infinite;

        span {
          color: rgb(255, 255, 255);
          font-weight: bold;
          font-family: 'Montserrat';
          font-size: 18px;
        }
      }

      .left, .right {
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .left img {
        transform: rotate(180deg);
      }
    }
  }

  .center-bottom {
    font-size: 1em;

    .play {
      margin-top: 40px;
      display: flex;
      width: 100%;
      justify-content: center;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        animation: glow 1.5s infinite;
      }
    }
  }
}
</style>