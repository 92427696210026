import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomeView.vue';
import About from '@/views/AboutView.vue';
import TopPage from "@/views/TopPage.vue";
import TaskPage from "@/views/TaskPage.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/task',
        name: 'Task',
        component: TaskPage,
    },
    {
        path: '/top/:page?',
        name: 'top',
        component: TopPage,
    },
    {
        path: '/game/:skin',
        name: 'Game',
        component: () => import('@/views/GameView.vue')
    },
    {
        path: '/invite',
        name: 'Invite',
        component: () => import('@/views/InviteView.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;