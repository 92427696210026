<template>
  <div class="main">
    <div class="header">
      <span :class="headerTab == 'top' ? 'activeHeader' : ''" @click="headerTab = 'top'">TOP</span>
      <span :class="headerTab == 'clans' ? 'activeHeader' : ''" @click="headerTab = 'clans'">CLANS</span>
    </div>

    <div class="head">
      <div class="info" v-if="!haveClan">
        <div class="info-text">
          <span>Create your clan!</span>
        </div>
        <div class="btn-go">
          <a @click.prevent="this.modal = !this.modal">
            <span>CREATE</span>
          </a>
        </div>
      </div>
      <div v-else-if="this.userStore.getClanOwnership" class="info">
        <div class="info-text">
          <span>Clan settings {{ this.userStore.getClan.name }}</span>
        </div>
        <div class="btn-go">
          <a @click.prevent="this.modalEdit = !this.modalEdit">
            <span>EDIT</span>
          </a>
        </div>
      </div>
      <div v-else class="info">
        <div class="info-text">
          <span>Your clan {{ this.userStore.getClan.name }}</span>
        </div>
        <div class="btn-go">
          <a @click.prevent="this.modalEdit = !this.modalEdit">
            <span>VIEW</span>
          </a>
        </div>
      </div>
    </div>

    <div v-if="headerTab == 'top'">
      <div class="tabs">
        <button @click="activeTab = 'top'" :class="{ active: activeTab === 'top' }">Players</button>
        <button @click="activeTab = 'clans'" :class="{ active: activeTab === 'clans' }">Clans</button>
      </div>

      <div class="content">
        <div class="list" v-if="activeTab === 'top'">
          <div class="item" v-for="(item, index) in this.top.players" :key="index">
            <div class="item-left">
              <span>{{ index + 1 }}</span>
              <span :class="{active: item.user_id == this.userId}">{{ item.username }}</span>
            </div>
            <div class="item-right">
              <span>{{ item.points }}</span>
            </div>
          </div>
        </div>
        <div class="list" v-if="activeTab === 'clans'">
          <div class="item" v-for="(item, index) in this.top.clan" :key="index">
            <div class="item-left">
              <span>{{ index + 1 }}</span>
              <span :class="{active: this.haveClan ? this.userStore.getClan.data.id == item.id : false }">{{
                  item.name
                }}</span>
            </div>
            <div class="item-right">
              <span>{{ item.clan_record }}</span>
              <div class="item-right-btn" v-if="this.haveClan ? this.userStore.getClan.data.id != item.id : true">
                <a @click="this.joinClan(item.id)" v-if="this.triggeredButton != item.id">
                  <span>JOIN</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="headerTab == 'clans'">
      <div class="tabs">
        <input class="tabs-input" placeholder="Search" @input="this.handleSearch"/>
      </div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in this.top.searchedClans" :key="index">
            <div class="item-left">
              <span>{{ index + 1 }}</span>
              <span :class="{active: this.haveClan ? this.userStore.getClan.data.id == item.id : false }">{{
                  item.name
                }}</span>
            </div>
            <div class="item-right">
              <span>{{ item.clan_record }}</span>
              <div class="item-right-btn" v-if="this.haveClan ? this.userStore.getClan.data.id != item.id : true">
                <a @click="this.joinClan(item.id)" v-if="this.triggeredButton != item.id">
                  <span>JOIN</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bottom">
      <router-link to="/" style="display: contents">
        <div class="btn">
          <span>HOME</span>
        </div>
      </router-link>
    </div>
  </div>

  <Transition name="bounce">
    <div class="modal_create" v-if="this.modal" @click="this.modal = false">
      <ModalClanCreateComponent @close="() => this.modal = false" @create="this.createClan"/>
    </div>
  </Transition>

  <Transition name="bounce">
    <div class="modal_create" v-if="this.modalEdit" @click="this.modalEdit = false">
      <ModalClanEditComponent :clan-id="this.userStore.getClan.data.id" @close="() => this.modalEdit = false"
                              @quit-clan="this.haveClan = false" :edit="this.userStore.getClanOwnership"/>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import '@/assets/style/font.scss';
@import "@/assets/style/modal.scss";

.main {
  height: 100svh;
  width: 100svw;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;

  span {
    padding-left: 10px;
    //color: aqua;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 21px;
  }

  .activeHeader {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff, 0 0 35px #228dff, 0 0 40px #228dff;
    color: white;
  }

  &:not(.activeHeader) {
    color: aqua;
  }
}

.tabs {
  display: flex;
  margin-left: 16px;
  border: 1px solid aqua;
  margin: 16px 16px 0px 16px;;
  border-radius: 20px 20px 0px 0px;
  padding: 16px;
  animation: glow 1.5s infinite;

  .tabs-input {
    width: 80%;
    padding: 10px;
    //margin: 10px 0;
    border: none;
    outline: none;
    color: white;
    font-weight: bold;
    font-size: large;
    //border-radius: 5px;

    background-color: #021C3D;

    &::placeholder {
      color: white;
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 21px;
    //font-family: 'Montserrat';
    font-weight: 500;
  }
}

.content {
  max-height: 460px;
  overflow-y: auto;
  margin: 0px 16px 0px 16px;;
  border: 1px solid aqua;
  border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  animation: glow 1.5s infinite;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: aqua;
    border-radius: 10px;
  }

}

.list {
  height: 40svh;
  display: flex;
  flex-direction: column;
  overflow: scroll !important;
  background-color: rgb(27, 63, 111);

  border-radius: 0 0 20px 20px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: rgb(27, 63, 111);
  border-bottom: 2px solid black;



  &-left {
    display: flex;
    gap: 20px;

    span:first-child {
      color: rgb(255, 147, 6);
    }

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 16px;
    }
  }

  &-right {
    display: flex;
    gap: 20px;
    align-items: center;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 16px;
    }

    &-btn {
      border-radius: 20px;
      border: 1px solid rgb(207, 66, 54);
      background-color: rgb(255, 18, 18);
      padding: 6px;

      span {
        color: white;
        font-weight: bold;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 0 6px 0 6px;
      }
    }
  }
}

.active {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff, 0 0 35px #228dff, 0 0 40px #228dff;
}

.head {
  display: flex;
  flex-direction: column;
  margin: 15px;
  gap: 20px;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid aqua;
    gap: 20px;
  }

  .info-text {
    text-align: left;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 16px;
    }
  }

  .btn-go {
    width: 50%;
    border-radius: 20px;
    border: 1px solid rgb(0, 191, 255);
    background-color: rgb(0, 191, 255);
    padding: 4px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 16px;
    }
  }
}

.bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 60%;
    border-radius: 20px;
    border: 1px solid rgb(61, 87, 171);
    background-color: transparent;
    padding: 4px;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 21px;
    }
  }
}

</style>

<script>
import {useUserStore} from "@/stores/userStore";
import ModalClanEditComponent from "@/components/ModalClanEditComponent.vue";
import ModalClanCreateComponent from "@/components/ModalClanCreateComponent.vue";

export default {
  components: {ModalClanCreateComponent, ModalClanEditComponent},
  data() {
    return {
      triggeredButton: null,
      activeTab: 'top',
      headerTab: 'top',
      modal: false,
      modalEdit: false,

      userId: this.userStore.user.id,

      top: {
        clan: this.userStore.top.clan,
        players: this.userStore.top.players,

        searchedClans: this.userStore.top.clan,
      },

      haveClan: false,
    };
  },
  methods: {
    createClan(data) {
      this.userStore.createClan({
        name: data.name,
        description: data.description
      })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.haveClan = true

            this.getTop()
          })
          .finally(() => {
            this.modal = false
          })

    },
    joinClan(clanId) {
      this.triggeredButton = clanId
      this.userStore.joinClan(clanId)
          .then(() => {
            this.haveClan = true
            this.triggeredButton = null
          })
    },
    handleSearch(event) {
      const search = event.target.value.toLowerCase();
      this.top.searchedClans = this.top.clan.filter((item) => {
        if (item.name.toLowerCase().includes(search)) {
          console.log(item, search)
          return item
        }
      })
    },
    getTop() {
      this.userStore.fetchTop()
          .then(({clans, players}) => {
            this.top = {
              clan: clans,
              players: players,
              searchedClans: clans
            }
          })
          .catch((err) => {
            console.error((err))
          })
    },
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.params.page != '') {
      this.headerTab = this.$route.params.page
    }

    this.haveClan = this.userStore.getHaveClan
    this.userId = this.userStore.getUser.id

    this.getTop()
  },
  setup() {
    const userStore = useUserStore()

    return {userStore}
  },
};
</script>