<template>
  <div class="header">
    <div class="left">
      <img :src="require('@/assets/profile.png')" alt="" class="profile-pic">
      <span>{{ username }}</span>
    </div>

    <div class="right">
      <div style="display: contents" v-if="this.clanStatus">
        <span class="clan_name">{{ this.clan }}</span>
        <div class="btn" @click="this.$router.push('/top/clans')">
          <span v-if="this.clanOwner">EDIT</span>
          <span v-else>INFO</span>
        </div>
      </div>
      <div v-else style="display: contents;">
        <span class="clan_name">{{ this.clan }}</span>
        <div class="btn" @click="this.$router.push('/top/clans')">
          <span>JOIN</span>
        </div>
      </div>
    </div>
  </div>
  <div class="gradient-line"></div>
</template>

<script>
import { useUserStore } from "@/stores/userStore";

export default {
  name: 'HeaderComponent',
  props: {
    username: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {
      clan: this.userStore.clan.name,
      clanStatus: this.userStore.clan.data.id ? true : false,
      clanOwner: false
    };
  },
  mounted() {
    this.clan = this.userStore.getClan.name;
    this.clanStatus = this.userStore.getHaveClan;
    if (this.clanStatus) {
      this.clanOwner = this.userStore.getClanOwnership;
    }
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
};
</script>

<style lang="scss">
@import '@/assets/style/font.scss';

.clan_name {
  color: white;
  max-width: 20svw;
  white-space: nowrap; // Отключаем перенос текста
  overflow: hidden;
  text-overflow: ellipsis; // Добавляем многоточие
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  width: 100svw; // Ограничиваем ширину
  max-width: 100svw;

  box-sizing: border-box; // Учитываем padding и border в ширине

  .left {
    display: flex;
    align-items: center;
    gap: 16px;

    .profile-pic {
      width: 32px;
      height: 32px;
    }

    span {
      color: white;
      font-family: 'Montserrat';
      font-size: 21px;
      white-space: nowrap; // Отключаем перенос текста
      overflow: hidden; // Обрезаем текст, если он не помещается
      text-overflow: ellipsis; // Добавляем многоточие
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: white;
      font-family: 'Montserrat';
      font-size: 21px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid aqua;
      background-color: rgba(255, 255, 255, 0);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);

      span {
        color: aqua;
        font-weight: bold;
        font-family: 'Montserrat';
        font-size: 21px;
      }
    }
  }
}

.gradient-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #00ffff, #ff1125);
}
</style>
