<template>
  <div class="modal_create-content" @click.stop="">
    <h2>CREATE CLAN</h2>
    <label for="clan-name">Clan name</label>
    <input type="text" v-model="this.createClanData.name" id="clan-name" placeholder="Enter clan name">

    <label for="clan-description">Clan description</label>
    <textarea id="clan-description" v-model="this.createClanData.description"
              placeholder="Enter clan description"></textarea>

    <div class="modal-buttons">
      <div class="btn"  @click="this.createClan" v-if="!this.buttonTriggered" style="background-color: aqua">
        <span>CREATE</span>
      </div>
      <div class="btn" v-else>
        <span>LOADING</span>
      </div>
      <div class="btn" @click="this.$emit('close')">
        <span>CLOSE</span>
      </div>
<!--      <button id="create-btn">Создать</button>-->
<!--      <button id="create-btn" v-else>Загрузка</button>-->
<!--      <button id="cancel-btn" @click="this.closeModal">Отменить</button>-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalClanCreateComponent',
  emits: ['create', 'close'],
  data() {
    return {
      createClanData: {
        name: null,
        description: null
      },
      buttonTriggered: false,
    }
  },
  methods: {
    createClan() {
      this.buttonTriggered = true
      this.$emit('create', this.createClanData)
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal_create-content {

  .btn {
    display: flex;
    justify-content: center;
    padding: 4px 10px 4px 10px;
    border-radius: 20px;
    border: 1px solid aqua;
    background-color: transparent;
    box-shadow: inset 0 -5px 6px rgba(0, 183, 255, 0.4);
    align-items: center;
    min-width: 72px;
    span {
      color: white;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: bold;
      height: min-content;
    }
  }


  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #021C3D;
  width: 300px;
  text-align: center;

  h2 {
    color: white;
  }

  label {
    font-size: larger;
    font-weight: 600;
    color: aqua;
    //color: white;
  }

  input, textarea {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    outline: 1px solid aqua;
    border-radius: 5px;

    color: white;

    background-color: #021C3D;
  }

  input::placeholder, textarea::placeholder {
    color: white;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  #cancel-btn {
    background-color: #ccc;
  }

  #create-btn {
    background-color: aqua;
    color: black;  }
}
</style>