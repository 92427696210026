<template>
  <div class="main">
    <div class="header">
      <span>TASKS</span>
    </div>

    <div class="list" v-if="tasks.length > 0">
      <div class="item" v-for="item in this.tasks" :key="item">
        <div class="item-text">
          <span>{{ item.description }}</span>
        </div>
        <div class="btn-go" :class="{completed: item.completed}">
          <a @click="clickTask(item)">
            <span>{{ item.type }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="bottom">
      <router-link to="/" style="display: contents">
        <div class="btn">
          <span>HOME</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/font.scss';

.main {
  height: 100svh;
  width: 100svw;
}

.header {
  display: flex;
  padding: 20px;

  span {
    color: aqua;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 21px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin: 15px;
  gap: 20px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid aqua;
    gap: 20px;
  }

  .item-text {
    text-align: left;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 16px;
    }
  }

  .btn-go {
    display: flex;
    justify-content: center;
    width: 50%;
    border-radius: 20px;
    border: 1px solid rgb(0, 191, 255);
    background-color: rgb(0, 191, 255);
    box-shadow: inset 0 -5px 6px rgb(0, 125, 174);
    padding: 4px;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 21px;
    }
  }

  .completed {
    background-color: #021C3D !important;
  }
}


.bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .btn {

    display: flex;
    justify-content: center;
    width: 60%;
    border-radius: 20px;
    border: 1px solid rgb(61, 87, 171);
    background-color: transparent;
    box-shadow: inset 0 -5px 5px rgba(0, 183, 255, 0.2);
    padding: 4px;

    span {
      color: white;
      font-weight: bold;
      font-family: 'Montserrat';
      font-size: 21px;
    }
  }
}

</style>

<script>
// import PreloaderComponent from "@/components/Preloader.vue";
// import HeaderComponent from "@/components/HeaderComponent.vue";
import {useUserStore} from "@/stores/userStore";
// import {useAssetsStore} from "@/stores/assetsStore";

export default {
  components: {},
  data() {
    return {
      tasks: [],
      completed: this.userStore.completedTasks
    };
  },
  methods: {
    clickTask({id, link, completed}) {
      if (!completed) {
        this.userStore.finishTask(id)
      }
      window.open(link, '_blank');
      this.tasks.map((item) => {
        if (item.id == id) {
          item.completed = true
        }
        return item
      })
    }
  },
  mounted() {
    this.completed = this.userStore.getCompletedTasks
    this.userStore.fetchTasks().then((data) => {
      data.forEach((item) => {
        if (this.completed.includes(item.id)) {
          item.completed = true;
        } else {
          item.completed = false;
        }
        this.tasks.push(item);
      })


    })
  },
  setup() {
    const userStore = useUserStore();
    // const assetsStore = useAssetsStore()
    return {userStore};
  },
};

</script>