import {
    defineStore
} from 'pinia'
import instance from "@/http-common";

export const useAssetsStore = defineStore('assets', {
    state: () => ({
        characters: [],
        characters_images: {},
        characters_data: {},
        eggs: {}
    }),
    getters: {
        getCharacterImagesById(id) {
            return this.characterImages[id]
        },
    },
    actions: {
        getCharacters() {
            return new Promise((resolve, reject) => {

                const url = `characters`

                instance.get(url)
                    .then((res) => {
                        this.characters = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getCharacterImages(id) {
            return new Promise((resolve, reject) => {

                const url = `characters/${id}/skin`

                if (this.characters_images.hasOwnProperty(`skin_${id}`)) {
                    // console.log('test', `skin_${id}`)
                    resolve({data: this.characters_images[`skin_${id}`]})
                }

                instance.get(url)
                    .then((res) => {
                        this.characters_images[`skin_${id}`] = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        getEggs() {
            return new Promise((resolve, reject) => {

                const url = `eggs`

                if (this.eggs.length > 0) {
                    resolve({data: this.eggs})
                }

                instance.get(url)
                    .then((res) => {
                        this.eggs = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        getCharacterData(id) {
            return new Promise((resolve, reject) => {

                const url = `characters/${id}/container`

                instance.get(url)
                    .then((res) => {
                        this.characters_data[`skin_${id}`] = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
    persist: true,
})