<template>
  <!--  <PreloaderComponent />-->
  <router-view v-slot="{ Component, route }">
    <component :is="Component" :key="route.path"/>
  </router-view>
  <audio style="display: none" ref="bg_music"
         src="https://yord_egg_backend.gellyzxc.ru/static/uploads/music.mp3" loop @canplay="this.userStore.setAudioLoaded()" />
</template>
<script>

import PreloaderComponent from "@/components/Preloader.vue";
import {storeToRefs} from 'pinia'
import {useWebAppClosingConfirmation} from 'vue-tg'
import {useUserStore} from "@/stores/userStore";

export default {
  name: 'App',
  components: {PreloaderComponent},
  watch: {
    audio() {

      if (this.userStore.getAudioState) {
        this.$refs.bg_music.volume = 0.2
        this.$refs.bg_music.play()
      } else {
        this.$refs.bg_music.pause()
        this.$refs.bg_music.volume = 0
      }
    }
  },
  setup() {
    const userStore = useUserStore();
    const {audio} = storeToRefs(userStore);

    // eslint-disable-next-line no-unused-vars
    const {enableClosingConfirmation, disableClosingConfirmation} = useWebAppClosingConfirmation()

    const tele = window.Telegram.WebApp; //Initializes the TELEGRAM BOT and

    tele.expand(); //Expands the app on the users' phone to 100% height

    enableClosingConfirmation()

    return {audio, userStore}
  },
}
</script>

<style>
@import '@/assets/style/font.scss';

html, body {
  height: 100svh !important;
  overflow: hidden;
}

body {
  background-color: #021C3D; /* Новый цвет фона для всего приложения */
  margin: 0; /* Удаление отступов */
}

* {
  font-family: 'Montserrat Alternates', sans-serif !important;
  //outline: 1px solid red;
}


@keyframes slide-out-right {
  0% {
    transform: translateX(0); /* Начальная позиция */
  }
  100% {
    transform: translateX(100vw); /* Уезжает вправо за пределы экрана */
  }
}

/* Анимация для .right */
@keyframes slide-out-left {
  0% {
    transform: translateX(0); /* Начальная позиция */
  }
  100% {
    transform: translateX(-100vw); /* Уезжает влево за пределы экрана */
  }
}

a, p {
  text-decoration: none !important;
}

.strike {
  background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
  color: black;
}

#app {
  height: 100%;
}
</style>
